<template>
  <div class="progress-wrapper">
    <div class="progress-container">
      <div class="progress-bar" :style="progressStyle"></div>
    </div>
    <div class="progress-labels">
      <span>0</span>
      <span>x1</span>
      <span>x2</span>
      <span>x3</span>
      <span>x4</span>
      <span>7500</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
      validator: (val) => val >= 0 && val <= 100
    },
    height: {
      type: String,
      default: '20px'
    },
    gradientColors: {
      type: Array,
      default: () => ['#00ffff', '#ff4a7a']
    }
  },
  computed: {
    progressStyle() {
      return {
        width: this.value + '%',
        height: this.height,
        background: `linear-gradient(to right, ${this.gradientColors[0]} 0%, ${this.gradientColors[1]} 100%)`
      };
    }
  }
};
</script>

<style scoped>
.progress-wrapper {
  width: 100%;
  color: white;
  text-align: center;
  font-family: Arial, sans-serif;
}

.progress-container {
  width: 100%;
  background-color: #2b2b2b;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}

.progress-bar {
  height: 100%;
  transition: width 0.3s ease-in-out;
}

.progress-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  font-size: 0.9rem;
  color: #ccc;
}
</style>